import { Box } from '@mui/material';
import React from 'react';

// @ts-ignore
import icon from '../../../../images/icon.svg';

type LogoProps = {
  alt: string;
  size?: 'small' | 'large';
};

const smallIcon = {
  width: 50,
  height: 50,
};

const largeIcon = {
  width: 100,
  height: 100,
};

const Logo: React.FC<LogoProps> = ({ alt, size = 'small' }) => {
  const imageData = size === 'small' ? smallIcon : largeIcon;

  return <Box component="img" src={icon} sx={imageData} alt={alt} />;
};

export { Logo };
